import '../ReportOverview.scss';
import './reportParameters.scss';

import * as React from 'react';

import {
	Box,
	Checkbox,
	FormControlLabel,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PrivacySettingsEnum from '../../../models/PrivacySettingsEnum';
import TemplateData from '../../../models/TemplateData';
import TripTypesEnum from '../../../models/TripTypesEnum';
import {TranslateText} from '../../../utils/Translations';
import Accordion from '../../Accordion/Accordion';
import AccordionSection from '../../Accordion/AccordionSection';
import {ListItem} from '../../Widgets/DeviceTemplateWidget/DeviceTemplateWidget';
import {OutsideBusinessHoursParameters} from '../OutsideBusinessHoursParameters';

type ReportParametersProps = {
	filledParametersCallback: (
		tripType: TripTypesEnum,
		tripDistance: number,
		includePrivateData: boolean,
		includeMaxSpeed: boolean,
		calculateVisitLastTripOfDayInReport: boolean,
		templates: string[],
		locations: string[],
		selectedSummaries: SummaryType[],
		objectTypes: string[],
		fuelTypes: string[],
		includeDevicesWithoutObjectConnection: boolean,
	) => void;
	unsavedDataCallback: (unsavedData: boolean) => void;
	setTripType: TripTypesEnum;
	setTripDistance: number;
	setIncludePrivateData: boolean;
	setIncludeMaxSpeed: boolean;
	setCalculateVisitLastTripOfDayInReport: boolean;
	customerTrackTypeSpecification: boolean;
	customerPrivacyLevel: PrivacySettingsEnum;
	customerTripTypes: TripTypesEnum;
	trackType: TripTypesEnum;
	templateDataType: TemplateData;
	userIsDriver: boolean;
	userCanSeePrivateData: boolean;
	durationTemplates: ListItem[];
	locations: ListItem[];
	digitalTemplates: ListItem[];
	objectTypes: ListItem[];
	fuelTypes: ListItem[];
	selectedTemplates: string[];
	selectedLocations: string[];
	selectedSummaries: SummaryType[];
	selectedObjectTypes: string[];
	selectedFuelTypes: string[];
	setIncludeDevicesWithoutObjectConnection: boolean;
};

type ReportParametersState = {
	tripType: TripTypesEnum;
	tripDistance: number;
	accordionExpandedIndex: number;
	includePrivateData: boolean;
	includeMaxSpeed: boolean;
	calculateVisitLastTripOfDayInReport: boolean;
	includeDevicesWithoutObjectConnection: boolean;
	selectedTemplates: string[];
	selectedLocations: ListItem[];
	selectedObjectTypes: ListItem[];
	selectedFuelTypes: ListItem[];
	selectedSummaries: SummaryType[];
};

const ALL = 'All';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export enum SummaryType {
	no = 0,
	day = 1,
	month = 2,
}

class ReportParameters extends React.PureComponent<ReportParametersProps, ReportParametersState> {
	constructor(props: ReportParametersProps) {
		super(props);
		this.state = {
			tripType: props.customerTripTypes,
			tripDistance: 0.1,
			accordionExpandedIndex: 0,
			includePrivateData: this.props.setIncludePrivateData,
			includeMaxSpeed: this.props.setIncludeMaxSpeed,
			calculateVisitLastTripOfDayInReport: this.props.setCalculateVisitLastTripOfDayInReport,
			selectedTemplates: this.props.selectedTemplates,
			selectedLocations: this.props.locations.filter((x) => this.props.selectedLocations.includes(x.id)),
			selectedObjectTypes: this.props.objectTypes.filter((x) => this.props.selectedObjectTypes.includes(x.id)),
			selectedFuelTypes: this.props.fuelTypes.filter((x) => this.props.selectedFuelTypes.includes(x.id)),
			selectedSummaries: this.props.selectedSummaries,
			includeDevicesWithoutObjectConnection: this.props.setIncludeDevicesWithoutObjectConnection
		};
	}

	setPreviouslyFilledData() {
		this.setState({
			...this.state,
			tripType: this.props.setTripType,
			tripDistance: this.props.setTripDistance,
			includePrivateData: this.props.setIncludePrivateData,
			includeMaxSpeed: this.props.setIncludeMaxSpeed,
			calculateVisitLastTripOfDayInReport: this.props.setCalculateVisitLastTripOfDayInReport,
			includeDevicesWithoutObjectConnection: this.props.setIncludeDevicesWithoutObjectConnection
		});
	}

	componentDidMount() {
		setTimeout(() => {
			this.setPreviouslyFilledData();
			if (this.props.customerPrivacyLevel === PrivacySettingsEnum.HidePrivateData) {
				this.setTripType({ checked: false }, TripTypesEnum.Private);
			}
		}, 100);
	}

	setIncludePrivateData(event: any) {
		this.setState({ includePrivateData: event.target.checked }, () => {
			this.props.filledParametersCallback(
				this.state.tripType,
				this.state.tripDistance,
				this.state.includePrivateData,
				this.state.includeMaxSpeed,
				this.state.calculateVisitLastTripOfDayInReport,
				this.state.selectedTemplates,
				this.state.selectedLocations.map((x) => x.id),
				this.state.selectedSummaries,
				this.state.selectedObjectTypes.map((x) => x.id),
				this.state.selectedFuelTypes.map((x) => x.id),
				this.state.includeDevicesWithoutObjectConnection
			);
		});
	}

	setIncludeMaxSpeed(event: any) {
		this.setState({ includeMaxSpeed: event.target.checked }, () => {
			this.props.filledParametersCallback(
				this.state.tripType,
				this.state.tripDistance,
				this.state.includePrivateData,
				event.target.checked,
				this.state.calculateVisitLastTripOfDayInReport,
				this.state.selectedTemplates,
				this.state.selectedLocations.map((x) => x.id),
				this.state.selectedSummaries,
				this.state.selectedObjectTypes.map((x) => x.id),
				this.state.selectedFuelTypes.map((x) => x.id),
				this.state.includeDevicesWithoutObjectConnection
			);
		});
	}

	setCalculateVisitLastTripOfDayInReport(event: any) {
		this.setState({ calculateVisitLastTripOfDayInReport: event.target.checked }, () => {
			this.props.filledParametersCallback(
				this.state.tripType,
				this.state.tripDistance,
				this.state.includePrivateData,
				this.state.includeMaxSpeed,
				event.target.checked,
				this.state.selectedTemplates,
				this.state.selectedLocations.map((x) => x.id),
				this.state.selectedSummaries,
				this.state.selectedObjectTypes.map((x) => x.id),
				this.state.selectedFuelTypes.map((x) => x.id),
				this.state.includeDevicesWithoutObjectConnection
			);
		});
	}
	setIncludeDevicesWithoutObjectConnection(event: any){
		this.setState({ includeDevicesWithoutObjectConnection: event.target.checked }, () => {
			this.props.filledParametersCallback(
				this.state.tripType,
				this.state.tripDistance,
				this.state.includePrivateData,
				this.state.includeMaxSpeed,
				this.state.calculateVisitLastTripOfDayInReport,
				this.state.selectedTemplates,
				this.state.selectedLocations.map((x) => x.id),
				this.state.selectedSummaries,
				this.state.selectedObjectTypes.map((x) => x.id),
				this.state.selectedFuelTypes.map((x) => x.id),
				event.target.checked
			);
		});
	}
	setTemplates(values: string[]) {
		this.setState(
			{
				selectedTemplates:
					values.length === 0 || values[values.length - 1] === ALL
						? [ALL]
						: values.filter((dt) => dt !== ALL),
			},
			() => {
				this.props.filledParametersCallback(
					this.state.tripType,
					this.state.tripDistance,
					this.state.includePrivateData,
					this.state.includeMaxSpeed,
					this.state.calculateVisitLastTripOfDayInReport,
					this.state.selectedTemplates,
					this.state.selectedLocations.map((x) => x.id),
					this.state.selectedSummaries,
					this.state.selectedObjectTypes.map((x) => x.id),
					this.state.selectedFuelTypes.map((x) => x.id),
					this.state.includeDevicesWithoutObjectConnection
				);
			}
		);
	}

	setLocations(values: (string | ListItem)[]) {
		this.setState(
			{
				selectedLocations:
					values.length === 0 ||
					(values.length === 1 && (values as ListItem[]).findIndex((x) => x.id === ALL) > -1) ||
					((values as ListItem[]).findIndex((x) => x.id === ALL) > -1 &&
						this.state.selectedLocations.findIndex((x) => x.id === ALL) === -1)
						? [this.props.locations[0]]
						: ((values as ListItem[]).filter((dt) => dt.id !== ALL) as ListItem[]),
			},
			() => {
				this.props.filledParametersCallback(
					this.state.tripType,
					this.state.tripDistance,
					this.state.includePrivateData,
					this.state.includeMaxSpeed,
					this.state.calculateVisitLastTripOfDayInReport,
					this.state.selectedTemplates,
					this.state.selectedLocations.map((x) => x.id),
					this.state.selectedSummaries,
					this.state.selectedObjectTypes.map((x) => x.id),
					this.state.selectedFuelTypes.map((x) => x.id),
					this.state.includeDevicesWithoutObjectConnection
				);
			}
		);
	}

	setObjectTypes(values: (string | ListItem) []){
		this.setState(
			{
				selectedObjectTypes:
					values.length === 0 ||
					(values.length === 1 && (values as ListItem[]).findIndex((x) => x.id === ALL) > -1) ||
					((values as ListItem[]).findIndex((x) => x.id === ALL) > -1 &&
						this.state.selectedObjectTypes.findIndex((x) => x.id === ALL) === -1)
						? [this.props.objectTypes[0]]
						: ((values as ListItem[]).filter((dt) => dt.id !== ALL) as ListItem[]),
			},
			() => {
				this.props.filledParametersCallback(
					this.state.tripType,
					this.state.tripDistance,
					this.state.includePrivateData,
					this.state.includeMaxSpeed,
					this.state.calculateVisitLastTripOfDayInReport,
					this.state.selectedTemplates,
					this.state.selectedLocations.map((x) => x.id),
					this.state.selectedSummaries,
					this.state.selectedObjectTypes.map((x) => x.id),
					this.state.selectedFuelTypes.map((x) => x.id),
					this.state.includeDevicesWithoutObjectConnection
				);
			}
		);
	}

	setFuelTypes(values: (string | ListItem) []){
		this.setState(
			{
				selectedFuelTypes:
					values.length === 0 ||
					(values.length === 1 && (values as ListItem[]).findIndex((x) => x.id === ALL) > -1) ||
					((values as ListItem[]).findIndex((x) => x.id === ALL) > -1 &&
						this.state.selectedFuelTypes.findIndex((x) => x.id === ALL) === -1)
						? [this.props.fuelTypes[0]]
						: ((values as ListItem[]).filter((dt) => dt.id !== ALL) as ListItem[]),
			},
			() => {
				this.props.filledParametersCallback(
					this.state.tripType,
					this.state.tripDistance,
					this.state.includePrivateData,
					this.state.includeMaxSpeed,
					this.state.calculateVisitLastTripOfDayInReport,
					this.state.selectedTemplates,
					this.state.selectedLocations.map((x) => x.id),
					this.state.selectedSummaries,
					this.state.selectedObjectTypes.map((x) => x.id),
					this.state.selectedFuelTypes.map((x) => x.id),
					this.state.includeDevicesWithoutObjectConnection
				);
			}
		);
	}

	setSummaries(values: SummaryType[]) {
		this.setState(
			{
				selectedSummaries:
					values.length === 0 || values[values.length - 1] === SummaryType.no
						? [SummaryType.no]
						: values.filter((st) => st !== SummaryType.no),
			},
			() => {
				this.props.filledParametersCallback(
					this.state.tripType,
					this.state.tripDistance,
					this.state.includePrivateData,
					this.state.includeMaxSpeed,
					this.state.calculateVisitLastTripOfDayInReport,
					this.state.selectedTemplates,
					this.state.selectedLocations.map((x) => x.id),
					this.state.selectedSummaries,
					this.state.selectedObjectTypes.map((x) => x.id),
					this.state.selectedFuelTypes.map((x) => x.id),
					this.state.includeDevicesWithoutObjectConnection
				);
			}
		);
	}

	setTripType(event: any, type: TripTypesEnum) {
		if (!type) {
			return;
		}

		if (event === null || event === undefined) {
			this.setState(
				{
					tripType: type,
				},
				() => {
					this.props.filledParametersCallback(
						this.state.tripType,
						this.state.tripDistance,
						this.state.includePrivateData,
						this.state.includeMaxSpeed,
						this.state.calculateVisitLastTripOfDayInReport,
						this.state.selectedTemplates,
						this.state.selectedLocations.map((x) => x.id),
						this.state.selectedSummaries,
						this.state.selectedObjectTypes.map((x) => x.id),
						this.state.selectedFuelTypes.map((x) => x.id),
						this.state.includeDevicesWithoutObjectConnection
					);
				}
			);

			return;
		}

		let newincludePrivateData = this.state.includePrivateData;
		if (
			this.props.customerPrivacyLevel === PrivacySettingsEnum.HidePrivateLocationAndTime &&
			type === TripTypesEnum.Private
		) {
			newincludePrivateData = event.target.checked;
		}

		if (event.target?.checked) {
			this.setState(
				{
					tripType: this.state.tripType ^ type,
					includePrivateData: newincludePrivateData,
				},
				() => {
					this.props.filledParametersCallback(
						this.state.tripType,
						this.state.tripDistance,
						this.state.includePrivateData,
						this.state.includeMaxSpeed,
						this.state.calculateVisitLastTripOfDayInReport,
						this.state.selectedTemplates,
						this.state.selectedLocations.map((x) => x.id),
						this.state.selectedSummaries,
						this.state.selectedObjectTypes.map((x) => x.id),
						this.state.selectedFuelTypes.map((x) => x.id),
						this.state.includeDevicesWithoutObjectConnection
					);
				}
			);
		} else if (type === (this.state.tripType & type)) {
			this.setState(
				{
					tripType: this.state.tripType - type,
					includePrivateData: newincludePrivateData,
				},
				() => {
					this.props.filledParametersCallback(
						this.state.tripType,
						this.state.tripDistance,
						this.state.includePrivateData,
						this.state.includeMaxSpeed,
						this.state.calculateVisitLastTripOfDayInReport,
						this.state.selectedTemplates,
						this.state.selectedLocations.map((x) => x.id),
						this.state.selectedSummaries,
						this.state.selectedObjectTypes.map((x) => x.id),
						this.state.selectedFuelTypes.map((x) => x.id),
						this.state.includeDevicesWithoutObjectConnection
					);
				}
			);
		}
	}

	setTripDistance(value: number) {
		let newValue = +value;
		if (value === null) {
			newValue = 0;
		}

		this.setState(
			{
				tripDistance: newValue,
			},
			() => {
				this.props.filledParametersCallback(
					this.state.tripType,
					newValue,
					this.state.includePrivateData,
					this.state.includeMaxSpeed,
					this.state.calculateVisitLastTripOfDayInReport,
					this.state.selectedTemplates,
					this.state.selectedLocations.map((x) => x.id),
					this.state.selectedSummaries,
					this.state.selectedObjectTypes.map((x) => x.id),
					this.state.selectedFuelTypes.map((x) => x.id),
					this.state.includeDevicesWithoutObjectConnection
				);
			}
		);
	}

	canIncludePrivateData(): boolean {
		return (
			//first condition
			((this.props.customerPrivacyLevel !== PrivacySettingsEnum.ShowPrivateData || !(this.props.customerTripTypes & TripTypesEnum.Private)) &&
				(this.props.userIsDriver || this.props.userCanSeePrivateData)) ||
			//second condition
			 (this.props.customerTrackTypeSpecification && this.props.customerPrivacyLevel !== PrivacySettingsEnum.ShowPrivateData)
		);
	}

	getExpanded(index: number) {
		const conditions: boolean[] = [
			this.canIncludePrivateData(),

			this.props.customerTrackTypeSpecification &&
				this.props.templateDataType !== TemplateData.ObjectCurrentMileage &&
				this.props.templateDataType !== TemplateData.CurrentObjectPosition,

			this.props.templateDataType !== TemplateData.ObjectCurrentMileage &&
				this.props.templateDataType !== TemplateData.CurrentObjectPosition &&
				this.props.templateDataType !== TemplateData.ObjectPosition &&
				this.props.templateDataType !== TemplateData.DriverPosition &&
				this.props.templateDataType !== TemplateData.ObjectMileage &&
				this.props.templateDataType !== TemplateData.DriverMileage,
		];

		let realIndex = index;
		for (let i = 0; i < index; i++) {
			if (!conditions[i]) {
				realIndex--;
			}
		}

		return this.state.accordionExpandedIndex === realIndex;
	}

	render() {
		return (
			<div className="categories-container">
				<div className="e-text active-step-title">{'5.' + TranslateText('reports.parameters')}</div>
				<div className="e-text">{TranslateText('reports.parametersDescription')}</div>

				<form id="parametersForm" noValidate={true} style={{ marginTop: 10 }}>
					<div className="form-group">
						<Accordion
							expandedCallback={(index) => {
								this.setState({ accordionExpandedIndex: index });
							}}
						>
							{this.canIncludePrivateData() &&
							this.props.templateDataType !== TemplateData.Location &&
							this.props.templateDataType !== TemplateData.MileageCO2 &&
							this.props.templateDataType !== TemplateData.DeviceExport? (
								<AccordionSection
									header={TranslateText('reports.parameter.privacyOptions')}
									expanded={this.getExpanded(0)}
								>
									<div className="trip-type-options">
										<FormControlLabel
											control={
												<Checkbox
													name="includePrivateData"
													color={'primary'}
													checked={this.state.includePrivateData}
													onChange={(e) => this.setIncludePrivateData(e)}
													size="small"
												/>
											}
											label={
												<Typography style={{ fontSize: 12, marginLeft: 5 }}>
													{TranslateText('reports.parameter.includePrivateData')}
												</Typography>
											}
										/>
										{(this.state.tripType === TripTypesEnum.None &&
											this.props.customerPrivacyLevel !== PrivacySettingsEnum.HidePrivateData) ||
										(!this.state.includePrivateData &&
											this.props.customerPrivacyLevel === PrivacySettingsEnum.HidePrivateData &&
											this.state.tripType === TripTypesEnum.None) ? (
											<div className="e-error" id="errorSelectedEntities">
												{TranslateText('fieldsValidations.noTrackTypeSelected')}
											</div>
										) : null}
									</div>
								</AccordionSection>
							) : null}
							{this.props.customerTrackTypeSpecification &&
							this.props.templateDataType !== TemplateData.ObjectCurrentMileage &&
							this.props.templateDataType !== TemplateData.CurrentObjectPosition &&
							this.props.templateDataType !== TemplateData.CumulativeRunningHours &&
							this.props.templateDataType !== TemplateData.DurationSummary &&
							this.props.templateDataType !== TemplateData.ObjectRunningHours &&
							this.props.templateDataType !== TemplateData.PersonRunningHours &&
							this.props.templateDataType !== TemplateData.Location &&
							this.props.templateDataType !== TemplateData.MileageCO2 &&
							this.props.templateDataType !== TemplateData.DeviceExport? (
								<AccordionSection
									header={TranslateText('reports.parameter.tripType')}
									expanded={this.getExpanded(1)}
								>
									<div className="trip-type-options">
										{this.props.trackType & TripTypesEnum.Business ? (
											<FormControlLabel
												control={
													<Checkbox
														name="tripType"
														color={'primary'}
														checked={
															TripTypesEnum.Business ===
															(this.props.customerTripTypes & TripTypesEnum.Business)
														}
														onChange={(e) => this.setTripType(e, TripTypesEnum.Business)}
														size="small"
														style={{ marginLeft: 5 }}
													/>
												}
												label={
													<Typography style={{ fontSize: 12, marginLeft: 5 }}>
														{TranslateText('reports.parameter.business')}
													</Typography>
												}
											/>
										) : null}
										{this.props.trackType & TripTypesEnum.Private &&
										this.props.customerPrivacyLevel !== PrivacySettingsEnum.HidePrivateData ? (
											<FormControlLabel
												control={
													<Checkbox
														name="tripType"
														color={'primary'}
														checked={
															TripTypesEnum.Private ===
															(this.props.customerTripTypes & TripTypesEnum.Private)
														}
														onChange={(e) => this.setTripType(e, TripTypesEnum.Private)}
														size="small"
														style={{ marginLeft: 5 }}
													/>
												}
												label={
													<Typography style={{ fontSize: 12, marginLeft: 5 }}>
														{TranslateText('reports.parameter.private')}
													</Typography>
												}
											/>
										) : null}
										{this.props.trackType & TripTypesEnum.Commuting ? (
											<FormControlLabel
												control={
													<Checkbox
														name="tripType"
														color={'primary'}
														checked={
															TripTypesEnum.Commuting ===
															(this.props.customerTripTypes & TripTypesEnum.Commuting)
														}
														onChange={(e) => this.setTripType(e, TripTypesEnum.Commuting)}
														size="small"
														style={{ marginLeft: 5 }}
													/>
												}
												label={
													<Typography style={{ fontSize: 12, marginLeft: 5 }}>
														{TranslateText('reports.parameter.commuting')}
													</Typography>
												}
											/>
										) : null}
										{(this.state.tripType === TripTypesEnum.None &&
											this.props.customerPrivacyLevel !== PrivacySettingsEnum.HidePrivateData) ||
										(!this.state.includePrivateData &&
											this.props.customerPrivacyLevel === PrivacySettingsEnum.HidePrivateData &&
											this.state.tripType === TripTypesEnum.None) ? (
											<div className="e-error" id="errorSelectedEntities">
												{TranslateText('fieldsValidations.noTrackTypeSelected')}
											</div>
										) : null}
									</div>
								</AccordionSection>
							) : null}
							{this.props.templateDataType !== TemplateData.ObjectCurrentMileage &&
							this.props.templateDataType !== TemplateData.CurrentObjectPosition &&
							this.props.templateDataType !== TemplateData.ObjectPosition &&
							this.props.templateDataType !== TemplateData.DriverPosition &&
							this.props.templateDataType !== TemplateData.ObjectMileage &&
							this.props.templateDataType !== TemplateData.DriverMileage &&
							this.props.templateDataType !== TemplateData.CumulativeRunningHours &&
							this.props.templateDataType !== TemplateData.DurationSummary &&
							this.props.templateDataType !== TemplateData.ObjectRunningHours &&
							this.props.templateDataType !== TemplateData.PersonRunningHours &&
							this.props.templateDataType !== TemplateData.Location &&
							this.props.templateDataType !== TemplateData.MileageCO2 &&
							this.props.templateDataType !== TemplateData.DeviceExport? (
								<AccordionSection
									header={TranslateText('reports.parameter.tripDistance')}
									expanded={this.getExpanded(2)}
								>
									<div>
										<span>{TranslateText('reports.parameter.showTripMinimumDistance')}</span>
										<TextField
											id="outlined-number"
											type="number"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												inputProps: {
													min: 0.1,
													style: {
														textAlign: 'center',
														fontSize: 12,
													},
												},
											}}
											name="tripDistance"
											value={this.state.tripDistance}
											style={{ width: 100 }}
											onChange={(e: any) => this.setTripDistance(e.target.value)}
										/>
										<span>km's</span>
									</div>
								</AccordionSection>
							) : null}
							{this.props.templateDataType === TemplateData.Trips ||
							this.props.templateDataType === TemplateData.ObjectWithoutDriver ||
							this.props.templateDataType === TemplateData.Visits ||
							this.props.templateDataType === TemplateData.OutsideBusinessHours ? (
								<AccordionSection
									header={TranslateText('reports.parameter.tripMiscellaneous')}
									expanded={this.getExpanded(3)}
								>
									<Box padding={'10px'}>
										<div className="report-parameters-duration-templates-selector" style={{flexDirection: 'column'}}>
											{(this.props.templateDataType === TemplateData.Trips ||
												this.props.templateDataType === TemplateData.ObjectWithoutDriver ||
												this.props.templateDataType === TemplateData.OutsideBusinessHours) && (
												<FormControlLabel
													control={
														<Checkbox
															name="includeMaxSpeed"
															color={'primary'}
															checked={this.state.includeMaxSpeed}
															onChange={(e) => this.setIncludeMaxSpeed(e)}
															size="small"
														/>
													}
													label={
														<Typography style={{ fontSize: 12, marginLeft: 5 }}>
															{TranslateText('reports.parameter.includeMaxSpeed')}
														</Typography>
													}
												/>
											)}
											{this.props.templateDataType === TemplateData.Visits ||
												this.props.templateDataType === TemplateData.Trips ||
												this.props.templateDataType === TemplateData.ObjectWithoutDriver ? (
												<FormControlLabel
													control={
														<Checkbox
															name="calculateVisitLastTripOfDayInReport"
															color={'primary'}
															checked={this.state.calculateVisitLastTripOfDayInReport}
															onChange={(e) =>
																this.setCalculateVisitLastTripOfDayInReport(e)
															}
															size="small"
														/>
													}
													label={
														<Typography style={{ fontSize: 12, marginLeft: 5 }}>
															{TranslateText('fields.calculateVisitLastTripOfDay')}
														</Typography>
													}
												/>
											) : null}
										</div>
									</Box>
								</AccordionSection>
							) : null}
							{this.props.templateDataType === TemplateData.DeviceExport? (
								<AccordionSection
									header={TranslateText('reports.parameter.deviceExport')}
									expanded={this.getExpanded(0)}
								>
									<Box padding={'10px'}>
										<div className="report-parameters-duration-templates-selector" style={{flexDirection: 'column'}}>
											{(this.props.templateDataType === TemplateData.DeviceExport) && (
												<FormControlLabel
													control={
														<Checkbox
															name="includeDevicesWithoutObjectConnection"
															color={'primary'}
															checked={this.state.includeDevicesWithoutObjectConnection}
															onChange={(e) => this.setIncludeDevicesWithoutObjectConnection(e)}
															size="small"
														/>
													}
													label={
														<Typography style={{ fontSize: 12, marginLeft: 5 }}>
															{TranslateText('reports.parameter.includeDevicesWithoutObjectConnection')}
														</Typography>
													}
												/>
											)}
										</div>
									</Box>
								</AccordionSection>
							) : null}
							{this.props.templateDataType === TemplateData.CumulativeRunningHours ||
							this.props.templateDataType === TemplateData.DurationSummary ? (
								<Box padding={'10px'}>
									<div className="report-parameters-duration-templates-selector">
										<span className={'select-title'}>{TranslateText('common.templates')}</span>
										<Select
											className="select-component"
											value={this.state.selectedTemplates}
											multiple
											fullWidth
											MenuProps={{
												anchorOrigin: {
													vertical: 'bottom',
													horizontal: 'left',
												},
												getContentAnchorEl: null,
												style: { zIndex: 15001 },
												classes: {
													paper: 'report-parameters-duration-templates-selector-menu-paper',
												},
											}}
											onChange={({ target: { value } }) => this.setTemplates(value as string[])}
											renderValue={() => {
												return this.props.durationTemplates
													.filter((d) => this.state.selectedTemplates.includes(d.id))
													.map((dt) => dt.display)
													.map((t) => (t === 'All' ? TranslateText('common.all') : t))
													.join(', ');
											}}
										>
											{this.props.durationTemplates.map((dt) => (
												<MenuItem
													key={dt.id}
													value={dt.id}
													className="report-parameters-duration-templates-selector-menu"
												>
													<Checkbox
														checked={this.state.selectedTemplates.includes(dt.id)}
														size="small"
													/>
													<ListItemText
														primary={dt.display}
														className="report-parameters-duration-templates-selector-list-item-text"
													/>
												</MenuItem>
											))}
										</Select>
									</div>
									<div
										className="report-parameters-duration-templates-selector"
										style={{ marginTop: 10 }}
									>
										{this.props.templateDataType === TemplateData.CumulativeRunningHours && (
											<>
												<span className={'select-title'}>
													{TranslateText('reports.parameter.summary')}
												</span>
												<Select
													className="select-component"
													value={this.state.selectedSummaries}
													multiple
													fullWidth
													MenuProps={{
														anchorOrigin: {
															vertical: 'bottom',
															horizontal: 'left',
														},
														getContentAnchorEl: null,
														style: { zIndex: 15001 },
														classes: {
															paper:
																'report-parameters-duration-templates-selector-menu-paper',
														},
													}}
													onChange={({ target: { value } }) =>
														this.setSummaries(value as SummaryType[])
													}
													renderValue={() => {
														return this.state.selectedSummaries
															.map((st) => TranslateText(`common.${SummaryType[st]}`))
															.join(', ');
													}}
												>
													<MenuItem
														value={SummaryType.no}
														className="report-parameters-duration-templates-selector-menu"
													>
														<Checkbox
															checked={this.state.selectedSummaries.includes(
																SummaryType.no
															)}
															size="small"
														/>
														<ListItemText
															primary={TranslateText('common.no')}
															className="report-parameters-duration-templates-selector-list-item-text"
														/>
													</MenuItem>
													<MenuItem
														value={SummaryType.day}
														className="report-parameters-duration-templates-selector-menu"
													>
														<Checkbox
															checked={this.state.selectedSummaries.includes(
																SummaryType.day
															)}
															size="small"
														/>
														<ListItemText
															primary={TranslateText('common.day')}
															className="report-parameters-duration-templates-selector-list-item-text"
														/>
													</MenuItem>
													<MenuItem
														value={SummaryType.month}
														className="report-parameters-duration-templates-selector-menu"
													>
														<Checkbox
															checked={this.state.selectedSummaries.includes(
																SummaryType.month
															)}
															size="small"
														/>
														<ListItemText
															primary={TranslateText('common.month')}
															className="report-parameters-duration-templates-selector-list-item-text"
														/>
													</MenuItem>
												</Select>
											</>
										)}
									</div>
								</Box>
							) : null}
							{this.props.templateDataType === TemplateData.ObjectRunningHours ||
							this.props.templateDataType === TemplateData.PersonRunningHours ? (
								<Box padding={'10px'}>
									<div className="report-parameters-duration-templates-selector">
										<span className={'select-title'}>{TranslateText('common.templates')}</span>
										<Select
											className="select-component"
											value={this.state.selectedTemplates}
											multiple
											fullWidth
											MenuProps={{
												anchorOrigin: {
													vertical: 'bottom',
													horizontal: 'left',
												},
												getContentAnchorEl: null,
												style: { zIndex: 15001 },
												classes: {
													paper: 'report-parameters-duration-templates-selector-menu-paper',
												},
											}}
											onChange={({ target: { value } }) => this.setTemplates(value as string[])}
											renderValue={() => {
												return this.props.digitalTemplates
													.filter((d) => this.state.selectedTemplates.includes(d.id))
													.map((dt) => dt.display)
													.map((t) => (t === 'All' ? TranslateText('common.all') : t))
													.join(', ');
											}}
										>
											{this.props.digitalTemplates.map((dt) => (
												<MenuItem
													key={dt.id}
													value={dt.id}
													className="report-parameters-duration-templates-selector-menu"
												>
													<Checkbox
														checked={this.state.selectedTemplates.includes(dt.id)}
														size="small"
													/>
													<ListItemText
														primary={dt.display}
														className="report-parameters-duration-templates-selector-list-item-text"
													/>
												</MenuItem>
											))}
										</Select>
									</div>
								</Box>
							) : null}
							{this.props.templateDataType === TemplateData.Location ? (
								<Box padding={'10px'}>
									<div className="report-parameters-duration-templates-selector">
										<span className={'select-title'}>{TranslateText('common.locations')}</span>
										<Autocomplete
											multiple
											fullWidth
											disableCloseOnSelect
											options={this.props.locations}
											value={this.state.selectedLocations}
											getOptionSelected={(option, value) => {
												return option.id == value.id;
											}}
											getOptionLabel={(option) => TranslateText(option.display)}
											onChange={(event: any, newValues: (string | ListItem)[]) => {
												this.setLocations(newValues);
											}}
											renderOption={(option, { selected }) => (
												<React.Fragment>
													<Checkbox
														icon={icon}
														checkedIcon={checkedIcon}
														style={{ marginRight: 8 }}
														checked={selected}
													/>
													{option.display}
												</React.Fragment>
											)}
											renderInput={(params) => <TextField {...params} variant="standard" />}
										></Autocomplete>
									</div>
								</Box>
							) : null}
							{this.props.templateDataType === TemplateData.MileageCO2 ? (
								<>
								<Box padding={'10px'}>
									<div className="report-parameters-duration-templates-selector">
										<span className={'select-title'} style={{minWidth: 90}}>{TranslateText('fields.objectType')}</span>
										<Autocomplete
											multiple
											fullWidth
											disableCloseOnSelect
											options={this.props.objectTypes}
											value={this.state.selectedObjectTypes}
											getOptionSelected={(option, value) => {
												return option.id == value.id;
											}}
											getOptionLabel={(option) => TranslateText(option.display)}
											onChange={(event: any, newValues: (string | ListItem)[]) => {
												this.setObjectTypes(newValues);
											}}
											renderOption={(option, { selected }) => (
												<React.Fragment>
													<Checkbox
														icon={icon}
														checkedIcon={checkedIcon}
														style={{ marginRight: 8 }}
														checked={selected}
													/>
													{option.display}
												</React.Fragment>
											)}
											renderInput={(params) => <TextField {...params} variant="standard" />}
										></Autocomplete>
									</div>
								</Box>
								<Box padding={'10px'}>
									<div className="report-parameters-duration-templates-selector">
										<span className={'select-title'} style={{minWidth: 90}}>{TranslateText('fields.fuelType')}</span>
										<Autocomplete
											multiple
											fullWidth
											disableCloseOnSelect
											options={this.props.fuelTypes}
											value={this.state.selectedFuelTypes}
											getOptionSelected={(option, value) => {
												return option.id == value.id;
											}}
											getOptionLabel={(option) => TranslateText(option.display)}
											onChange={(event: any, newValues: (string | ListItem)[]) => {
												this.setFuelTypes(newValues);
											}}
											renderOption={(option, { selected }) => (
												<React.Fragment>
													<Checkbox
														icon={icon}
														checkedIcon={checkedIcon}
														style={{ marginRight: 8 }}
														checked={selected}
													/>
													{option.display}
												</React.Fragment>
											)}
											renderInput={(params) => <TextField {...params} variant="standard" />}
										></Autocomplete>
									</div>
								</Box>
								</>
							) : null}
						</Accordion>
					</div>
					{this.props.templateDataType === TemplateData.OutsideBusinessHours ? (
						<div className="form-group">
							<OutsideBusinessHoursParameters unsavedDataCallback={this.props.unsavedDataCallback} />
						</div>
					) : null}
				</form>
			</div>
		);
	}
}

export default ReportParameters;
