import './newReport.scss';

import { validateWorkingDay } from 'components/WorkingHoursSchedule/WorkingHoursUtils';
import moment from 'moment';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { availableCustomersActions } from 'store/AvailableCustomers';

import { Button } from '@material-ui/core';

import ClaimType from '../../../authorization/ClaimType';
import GlobalSettings from '../../../GlobalSettings.json';
import EntityData from '../../../models/EntityData';
import EntityTypeEnum from '../../../models/EntityTypeEnum';
import PersonTypeFilterEnum from '../../../models/PersonTypeFilterEnum';
import PrivacySettingsEnum from '../../../models/PrivacySettingsEnum';
import ReportDaysEnum from '../../../models/ReportDaysEnum';
import ReportFrequencyEnum from '../../../models/ReportFrequencyEnum';
import ReportGenerateTypeEnum from '../../../models/ReportGenerateTypeEnum';
import ReportPeriodsEnum, { ReportPeriodType } from '../../../models/ReportPeriodsEnum';
import ReportRenderFormatEnum from '../../../models/ReportRenderFormatEnum';
import TemplateData from '../../../models/TemplateData';
import TripTypesEnum from '../../../models/TripTypesEnum';
import DateTimeUtil from '../../../shared/datetime/DateTimeUtil';
import { ApplicationState } from '../../../store';
import { reportStoreActionCreators, ReportTemplate } from '../../../store/ReportStore';
import ajaxUtil from '../../../utils/Ajax';
import { TranslateText } from '../../../utils/Translations';
import { ListItem } from '../../Widgets/DeviceTemplateWidget/DeviceTemplateWidget';
import {
	filterSelectedOutsideBusinessHours,
	getDefaultBusinessHoursParameters,
} from '../OutsideBusinessHoursParameters/OutsideBusinessHoursUtils';
import BasicReportInformation from './BasicReportInformation';
import ReportGenerate from './ReportGenerate';
import { ReportObjectPerson } from './ReportObjectPerson';
import ReportParameters, { SummaryType } from './ReportParameters';
import ReportPeriod from './ReportPeriod';
import ReportsTemplates from './ReportsTemplates';
import StepsLookup from './StepsLookup';
import ObjectTypeDataSource from "../../../shared/staticDataSources/ObjectTypeDataSource";
import FuelTypeDataSource from "../../../shared/staticDataSources/FuelTypeDataSource";

const mapState = (state: ApplicationState) => {
	return {
		customerId: state.currentSession.customerId,
		currentSession: state.currentSession,
		personId: state.currentSession.personId,
		userId: state.currentSession.aspNetUserId,
		customerPrivateTrip: state.currentSession.customer.featuresSettings.privacySettings,
		customerTrackTypeSpecification: state.currentSession.customer.featuresSettings.trackTypeSpecification,
		globalCustomer: state.globalCustomer.filteredCustomer,
		customerTripTypes: state.currentSession.customer.featuresSettings.tripTypes,
		customerPrivacyLevel: state.currentSession.customer.featuresSettings.privacySettings,
		reportData: state.reportStore,
		driverIdentification: state.currentSession.customer.featuresSettings.driverIdentification,
		trackTypeSpecification: state.currentSession.customer.featuresSettings.trackTypeSpecification,
		outsideBusinessHours: state.currentSession.customer.featuresSettings.outsideBusinessHours,
		locations: state.currentSession.customer.featuresSettings.locations,
		templates: state.currentSession.customer.featuresSettings.templates,
		reportFormatPreference:
			state.currentSession.reportsSettings.defaultOutputSelectionInReport ??
			state.currentSession.customer.reportsSettings.defaultOutputSelectionInReport,
		userIsDriver: state.currentSession.isDriver,
		isDriverWithNoAuthorization: state.currentSession.isDriverWithNoAuthorization,
		userCanSeePrivateData: !!state.oidc.user?.profile[ClaimType.ShowPrivateTrips],
		email: state.currentSession.email,
		timezonesDataSource: state.commonData.timezonesDataSource,
		personCalculateVisitLastTripOfDayInReports:
			state.currentSession.reportsSettings.calculateVisitLastTripOfDayInReport ??
			state.currentSession.customer.reportsSettings.calculateVisitLastTripOfDayInReport,
		assetTracking: state.globalCustomer?.filteredCustomer
			? state.globalCustomer.filteredCustomer.featuresSettings.assetTracking
			: state.currentSession.customer?.featuresSettings.assetTracking
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		reportStoreActionCreators: bindActionCreators(reportStoreActionCreators, dispatch),
		availableCustomersActions: bindActionCreators(availableCustomersActions, dispatch),
	};
}

const connector = connect(mapState, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type NewReportProps = PropsFromRedux & {
	active: boolean;
	redirectAfterSaveCallback: (index: number, resetGeneratedReportsFilters?: boolean) => void;
	getCurrentUserEntityDataCallback: () => any;
	unsavedDataCallback: (unsavedData: boolean) => void;
};

const sortData = (reportTemplatesCollection: ReportTemplate[][]) => {
	reportTemplatesCollection.forEach((reportTemplates: ReportTemplate[]) => {
		reportTemplates.forEach((reportTemplate: ReportTemplate) => {
			reportTemplate.category = TranslateText(reportTemplate.category);
			reportTemplate.name = TranslateText(reportTemplate.name);
		});
	});

	reportTemplatesCollection.forEach((reportTemplates: ReportTemplate[]) => {
		reportTemplates.sort((a, b) => a.name.localeCompare(b.name));
	});

	return reportTemplatesCollection.sort((a, b) => a[0].category.localeCompare(b[0].category));
};

class NewReport extends React.PureComponent<
	NewReportProps,
	{
		driversObjectsReceiversCount: { drivers: string[]; objects: string[]; groups: string[]; receivers: string[] };
		durationTemplates: ListItem[];
		digitalTemplates: ListItem[];
		locations: ListItem[];
		objectTypes: ListItem[];
		fuelTypes: ListItem[];
		disableGenerate: boolean;
		validationsSteps: number[];
		previousTemplateDataType: TemplateData;
		stepsNavigated: number[];
	}
> {
	customerTimezoneId: string = null;
	defaultStartDateTime: Date = new Date();
	defaultEndDateTime: Date = new Date();
	wizardPageRef = React.createRef<HTMLDivElement>();
	stepsLookupRef = React.createRef<{ forceRevalidation: () => void}>();

	constructor(props: NewReportProps) {
		super(props);

		this.state = {
			driversObjectsReceiversCount: { drivers: [], objects: [], groups: [], receivers: [] },
			durationTemplates: [],
			digitalTemplates: [],
			locations: [],
			objectTypes: [],
			fuelTypes: [],
			disableGenerate: false,
			validationsSteps: [],
			previousTemplateDataType: null,
			stepsNavigated: [1],
		};

		this.defaultStartDateTime.setHours(0, 0, 0);
		this.defaultEndDateTime.setHours(23, 59, 59);

		this.getCurrentUserEntityData();
		if (!this.props.reportData.isFavorite && !this.props.reportData.isPlanned) {
			this.props.reportStoreActionCreators.setReportData({
				reportPeriodType: this.getDefaultReportPeriodType(),
				reportHeaderTitle: TranslateText('reports.newReport'),
				calculateVisitLastTripOfDayInReport: this.getDefaultCalculateVisitLastTripOfDayInReports(),
			});
			this.props.reportStoreActionCreators.setTripData({
				tripDistanceParameter: this.props.currentSession.reportsSettings?.defaultMinimalTripInReport ?? 0.1,
				tripTypeParameter: this.getUserDefaultTripType(),
			});
			this.props.reportStoreActionCreators.setScheduleData({
				formatType: this.props?.reportFormatPreference ?? ReportRenderFormatEnum.pdf,
				scheduleTimezoneId: this.customerTimezoneId,
			});
		}
	}

	getDefaultCalculateVisitLastTripOfDayInReports(): boolean {
		return this.props.personCalculateVisitLastTripOfDayInReports;
	}

	getDefaultCustomerTrackTypeSpecification(): boolean {
		return this.props.customerTrackTypeSpecification;
	}

	getDefaultTripType(): TripTypesEnum {
		return this.props.customerTripTypes;
	}

	getUserDefaultTripType(): TripTypesEnum {
		return this.props.customerPrivacyLevel === PrivacySettingsEnum.HidePrivateData
			? this.props.customerTripTypes ^ TripTypesEnum.Private
			: this.props.customerTripTypes;
	}

	getDefaultReportPeriodType() {
		if (this.props.currentSession.reportsSettings === null) {
			return ReportPeriodsEnum.LastWeek;
		}
		const defaultPeriodType = ReportPeriodsEnum.GetReportPeriodTypeByValue(
			this.props.currentSession.reportsSettings.defaultPeriodSelectionInReport
		);
		return defaultPeriodType ?? ReportPeriodsEnum.LastWeek;
	}

	getCurrentUserEntityData() {
		const currentUserEntityData = this.props.getCurrentUserEntityDataCallback();
		if (this.props.email) {
			this.props.reportStoreActionCreators.setScheduleData({
				receivers: currentUserEntityData,
			});
		}
	}

	getCustomerTimezoneId(): string {
		const customerId = this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId;

		if (!customerId) {
			return null;
		}

		ajaxUtil
			.get<{ timezoneId: string }>(`${GlobalSettings.customersMaintenanceApi}/${customerId}`)
			.then((customerInfo) => {
				this.customerTimezoneId = customerInfo.timezoneId;
				this.props.reportStoreActionCreators.setScheduleData({
					scheduleTimezoneId: customerInfo.timezoneId,
				});
				this.props.reportStoreActionCreators.setReportData({
					timeZone: customerInfo.timezoneId,
				});
			});
	}

	async getCustomerTimezoneIdWithoutImpersonation(): Promise<string> {
		const customerId = this.props.customerId;

		if (!customerId) {
			return null;
		}

		return (await ajaxUtil.get<{ timezoneId: string }>(`${GlobalSettings.customersMaintenanceApi}/${customerId}`))
			.timezoneId;
	}

	getReportsTemplates(): void {
		ajaxUtil
			.get(
				`${GlobalSettings.reportingApi}/GetAvailableReportsTemplates/${this.props.customerId}?driverIdentification=${this.props.driverIdentification}&trackTackSpecification=${this.props.trackTypeSpecification}&outsideBusinessHours=${this.props.outsideBusinessHours}&locations=${this.props.locations}&templates=${this.props.templates}`
			)
			.then((data) => {
				const sortedData = sortData(data as ReportTemplate[][]);
				this.props.reportStoreActionCreators.setData(sortedData);
			});
	}

	componentDidUpdate(prevProps: NewReportProps) {
		if (prevProps.active !== this.props.active && !this.props.active) {
			this.resetReport();
		}
		if (prevProps.globalCustomer?.id !== this.props.globalCustomer?.id) {
			this.getReportsTemplates();
			this.getCustomerTimezoneId();
		}
		if (this.props.active) {
			this.props.availableCustomersActions.toggleSelection(false);
		}

		if (
			prevProps.globalCustomer !== this.props.globalCustomer ||
			prevProps.reportData.templateEntityType !== this.props.reportData.templateEntityType
		) {
			this.getNumberOfDriversObjectsReceivers();
		}

		if (prevProps.reportData?.currentStep !== this.props.reportData?.currentStep) {
			setTimeout(() => {
				this.wizardPageRef.current?.scrollIntoView();
			}, 0);

			if(this.props.reportData.currentStep === 6)
			{
				if (this.stepsLookupRef.current) {
					this.stepsLookupRef.current.forceRevalidation();
					this.setState({previousTemplateDataType: this.props.reportData.templateDataType});
				}
			}
		}
	}

	hasUnsavedData(): boolean {
		return this.props.reportData.unsavedData;
	}

	componentDidMount() {
		this.getReportsTemplates();
		if (!this.customerTimezoneId) {
			this.getCustomerTimezoneId();
		}

		if (this.props.active) {
			this.props.availableCustomersActions.toggleSelection(false);
		}
		this.handleIncludePrivateDataChangedCallback(this.canIncludePrivateData());

		this.getNumberOfDriversObjectsReceivers();

		this.getTemplatesForCustomer();
	}

	async getTemplatesForCustomer() {
		const durationTemplates = await ajaxUtil.get<ListItem[]>(
			`${GlobalSettings.listsApi}/durationTemplatesForCustomer?customerId=${
				this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId
			}`
		);

		const digitalTemplates = await ajaxUtil.get<ListItem[]>(
			`${GlobalSettings.listsApi}/digitalTemplatesForCustomer?customerId=${
				this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId
			}`
		);

		const locations = await ajaxUtil.get<ListItem[]>(
			`${GlobalSettings.listsApi}/locationsForCustomer?customerId=${
				this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId
			}`
		);
		const objectTypes = ObjectTypeDataSource.dataSource().map((x) => {
			return {
				id: x.id.valueOf().toString(),
				display: x.display
			};
		});
		const fuelTypes = FuelTypeDataSource.dataSource().map((x) => {
			return {
				id: x.id.valueOf().toString(),
				display: x.display
			}
		});
		this.setState({
			durationTemplates: [{ id: 'All', display: TranslateText('common.all') }, ...durationTemplates],
			digitalTemplates: [{ id: 'All', display: TranslateText('common.all') }, ...digitalTemplates],
			locations: [{ id: 'All', display: TranslateText('common.all') }, ...locations],
			objectTypes: [{id: 'All', display: TranslateText('common.all')}, ...objectTypes],
			fuelTypes: [{id: 'All', display: TranslateText('common.all')}, ...fuelTypes],
		});
	}

	componentWillUnmount() {
		this.resetReport();
	}

	getNumberOfDriversObjectsReceivers() {
		const url = GlobalSettings.reportingApi + '/GetDriversObjectsReceiversCount';
		ajaxUtil
			.post(url, {
				customerId: this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId,
				showPersons: true,
				showObjects: true,
				showInactive: true,
				personType:
					this.props.reportData.templateEntityType === EntityTypeEnum.PersonReport
						? PersonTypeFilterEnum.All
						: PersonTypeFilterEnum.Driver,
				filterText: '',
			})
			.then((data) => {
				this.setState({
					driversObjectsReceiversCount: data as any,
				});
			});
	}

	goToNextStep() {
		let val = this.props.reportData.currentStep;
		if (this.props.reportData.currentStep < 6) {
			if(this.props.reportData.currentStep === 2 &&
				(this.props.reportData.templateDataType === TemplateData.DeviceExport)
			) {
				val = val + 3;
				this.props.reportStoreActionCreators.setCurrentStep(val);
			}
			else if (
				(this.props.reportData.currentStep === 4 &&
					(this.props.reportData.templateDataType === TemplateData.ObjectRawMessage ||
						this.props.reportData.templateDataType === TemplateData.ObjectCurrentMileage ||
						this.props.reportData.templateDataType === TemplateData.CurrentObjectPosition ||
						this.props.reportData.templateDataType === TemplateData.LoginReason ||
						this.props.reportData.templateDataType === TemplateData.DriverRegistration ||
						this.props.reportData.templateDataType === TemplateData.ObjectRegistration)) ||
				(this.props.reportData.templateEntityType === EntityTypeEnum.Person &&
					this.props.reportData.currentStep === 3 &&
					this.state.driversObjectsReceiversCount.drivers.length === 1 &&
					this.state.driversObjectsReceiversCount.drivers[0] === this.props.personId &&
					!this.state.driversObjectsReceiversCount.groups.length)
			) {
				val = val + 2;
				this.props.reportStoreActionCreators.setCurrentStep(val);
			} else {
				val = val + 1;
				this.props.reportStoreActionCreators.setCurrentStep(val);
			}
			let stepsNavigated = this.state.stepsNavigated;
			stepsNavigated.push(val);
			this.setState({stepsNavigated: stepsNavigated});
		}
	}

	goToStep(stepNumber: number) {
		if(!this.state.stepsNavigated.some((x) => x === stepNumber)) {
			if ((stepNumber === 3 &&
					(this.props.reportData.templateDataType === TemplateData.DeviceExport)) ||
				(stepNumber === 4 &&
					(this.props.reportData.templateDataType === TemplateData.DeviceExport)) ||
				(stepNumber === 5 &&
					(this.props.reportData.templateDataType === TemplateData.ObjectRawMessage ||
						this.props.reportData.templateDataType === TemplateData.ObjectCurrentMileage ||
						this.props.reportData.templateDataType === TemplateData.CurrentObjectPosition ||
						this.props.reportData.templateDataType === TemplateData.LoginReason ||
						this.props.reportData.templateDataType === TemplateData.DriverRegistration ||
						this.props.reportData.templateDataType === TemplateData.ObjectRegistration))
			) {
				return;
			}
			if (this.props.reportData.currentStep > stepNumber || !this.navigationDisabled(stepNumber - 1)) {
				this.props.reportStoreActionCreators.setCurrentStep(stepNumber);
				let stepsNavigated = this.state.stepsNavigated;
				stepsNavigated.push(stepNumber);
				this.setState({stepsNavigated: stepsNavigated});
			}
		}
		else
		{
			this.props.reportStoreActionCreators.setCurrentStep(stepNumber);
		}
	}

	setStyleClassBasedOnCurrentStep(stepNumber: number, currentStep: number): string {
		let validations = [...this.state.validationsSteps];
		if(currentStep === stepNumber) {
			validations = validations.filter((x) => x != stepNumber);
			if(validations?.length !== this.state.validationsSteps?.length) {
				this.setState({validationsSteps: [...validations], stepsNavigated: []});
			}
		}

		return currentStep === stepNumber ? 'selected-wizard-step' : validations.some((x) => x === stepNumber) ? 'error-wizard-step' : '';
	}

	navigationDisabled(currentStep: number): boolean {
		const step1State = !(this.props.reportData.selectedTemplateId !== null);
		const step2State = !(this.props.reportData.reportName && this.props.reportData.reportName.trim().length > 0);
		const step3State = ((!this.props.reportData.isValidPeriod) &&
			!(this.props.reportData.templateDataType === TemplateData.DeviceExport));
		const step4State =
			(!(this.props.reportData.templateDataType === TemplateData.DeviceExport)) &&
			(!(this.props.reportData.reportSelectedEntities.length > 0) ||
			((this.props.reportData.templateDataType === TemplateData.DriverPosition ||
				this.props.reportData.templateDataType === TemplateData.ObjectPosition) &&
				(this.props.reportData.reportSelectedEntities.length > 10 ||
					this.props.reportData.reportSelectedEntities.find((x) => x.entityType === EntityTypeEnum.Group)) !==
					undefined));
		let step5State =
			(!this.props.reportData.includePrivateData &&
				this.props.customerPrivacyLevel === PrivacySettingsEnum.HidePrivateData &&
				this.props.reportData.tripTypeParameter === TripTypesEnum.None) ||
			(this.props.reportData.tripTypeParameter === TripTypesEnum.None &&
				this.props.customerPrivacyLevel !== PrivacySettingsEnum.HidePrivateData);
		if (this.props.reportData.templateDataType === TemplateData.OutsideBusinessHours) {
			step5State =
				step5State ||
				this.props.reportData.outsideBusinessHours?.workingDays?.some((x) => !validateWorkingDay(x));
		}

		const step6State = !(
			this.props.reportData.generateType === ReportGenerateTypeEnum.GenerateNow ||
			!this.props.reportData.sendAsMail ||
			(this.props.reportData.sendAsMail && this.props.reportData.receivers.length > 0)
		);

		switch (currentStep) {
			case 1:
				return step1State;
			case 2:
				return step1State || step2State;
			case 3:
				return step1State || step2State || step3State;
			case 4:
				return step1State || step2State || step3State || step4State;
			case 5:
				return step1State || step2State || step3State || step4State || step5State;
			case 6:
				return step1State || step2State || step3State || step4State || step5State || step6State;
			default:
				return true;
		}
	}

	isStepVisible(stepNumber: number, currentStep: number) {
		return currentStep === stepNumber;
	}

	handleTemplateSection(
		templateId: string,
		templateName: string,
		templateCategory: string,
		templateCategoryId: string,
		templateEntityType: EntityTypeEnum,
		templateDataType: TemplateData
	) {
		if(templateDataType !== this.props.reportData.templateDataType) {
			if (this.props.reportData.templateDataType !== this.state.previousTemplateDataType && templateDataType !== this.state.previousTemplateDataType) {
				this.setState({previousTemplateDataType: this.props.reportData.templateDataType});
			}
		}
		if (this.props.reportData.templateEntityType !== templateEntityType) {
			let reportSelectedEntities: EntityData[] = [];
			if (
				templateEntityType === EntityTypeEnum.Person &&
				this.props.userIsDriver &&
				this.state.driversObjectsReceiversCount.drivers.length === 1 &&
				this.state.driversObjectsReceiversCount.drivers[0] === this.props.personId &&
				!this.state.driversObjectsReceiversCount.groups.length
			) {
				reportSelectedEntities = this.props.getCurrentUserEntityDataCallback();
			}

			this.props.reportStoreActionCreators.setReportingEntitiesData({
				reportSelectedEntities,
				unsavedData: true,
			});
		}

		if (templateDataType === TemplateData.OutsideBusinessHours && !this.props.reportData.outsideBusinessHours) {
			this.props.reportStoreActionCreators.setOutsideBusinessHours(
				filterSelectedOutsideBusinessHours(getDefaultBusinessHoursParameters())
			);
		} else if (
			templateDataType !== TemplateData.OutsideBusinessHours &&
			!!this.props.reportData.outsideBusinessHours
		) {
			this.props.reportStoreActionCreators.setOutsideBusinessHours(null);
		}

		if (this.props.reportData.reportName === null || this.props.reportData.selectedTemplateId !== templateId) {
			this.props.reportStoreActionCreators.setReportData({
				selectedTemplateId: templateId,
				reportName: templateName,
				reportTemplateName: templateName,
				reportCategory: templateCategory,
				reportCategoryId: templateCategoryId,
				unsavedData: true,
				templateEntityType: templateEntityType,
				templateDataType: templateDataType,
			});
		} else {
			this.props.reportStoreActionCreators.setReportData({
				selectedTemplateId: templateId,
				reportTemplateName: templateName,
				reportCategory: templateCategory,
				reportCategoryId: templateCategoryId,
				unsavedData: true,
				templateEntityType: templateEntityType,
				templateDataType: templateDataType,
			});
		}

		this.props.unsavedDataCallback(true);
	}

	handleReportBasicData(reportName: string, reportNotes: string) {
		this.props.reportStoreActionCreators.setReportData({
			reportName: reportName,
			reportHeaderTitle:
				reportName === null || !(reportName.trim().length > 0)
					? TranslateText('reports.newReport')
					: reportName,
			reportNotes: reportNotes,
			unsavedData: true,
		});
		this.props.unsavedDataCallback(true);
	}

	handlePeriodCallback(periodType: ReportPeriodType, customDateInterval?: Date[]) {
		let periodInThePast = false;
		if (periodType === ReportPeriodsEnum.CustomPeriod && customDateInterval && customDateInterval.length > 1) {
			if (customDateInterval[1] < new Date()) {
				periodInThePast = true;
			}
		}
		this.props.reportStoreActionCreators.setReportData({
			reportPeriodType: periodType,
			reportCustomPeriodInterval: customDateInterval,
			unsavedData: true,
			periodInThePast: periodInThePast,
		});

		this.props.unsavedDataCallback(true);
	}

	handlePeriodValidationChangedCallback(isValidPeriod?: boolean) {
		this.props.reportStoreActionCreators.setValidPeriod(isValidPeriod);
	}

	handleIncludeInactiveChangedCallback(includeInactive: boolean) {
		this.props.reportStoreActionCreators.setIncludeInactive(includeInactive);
	}

	handleObjectPersonData(entities: EntityData[]) {
		this.props.reportStoreActionCreators.setReportingEntitiesData({
			reportSelectedEntities: entities,
			unsavedData: true,
		});
		this.props.unsavedDataCallback(true);
	}

	handleIncludePrivateDataChangedCallback(includePrivateData: boolean) {
		this.props.reportStoreActionCreators.setIncludePrivateData(includePrivateData);
	}

	handleParametersData(
		tripType: TripTypesEnum,
		tripDistance: number,
		includePrivateData: boolean,
		includeMaxSpeed: boolean,
		calculateVisitLastTripOfDayInReport: boolean,
		templates: string[],
		locations: string[],
		selectedSummaries: SummaryType[],
		objectTypes: string[],
		fuelTypes: string[],
		includeDevicesWithoutObjectConnection: boolean
	) {
		this.props.reportStoreActionCreators.setTripData({
			tripDistanceParameter: tripDistance,
			tripTypeParameter: tripType,
			includePrivateData: includePrivateData,
			includeMaxSpeed: includeMaxSpeed,
			calculateVisitLastTripOfDayInReport: calculateVisitLastTripOfDayInReport,
			templates: templates,
			locations: locations,
			selectedSummaries: selectedSummaries,
			unsavedData: true,
			objectTypes: objectTypes,
			fuelTypes: fuelTypes,
			includeDevicesWithoutObjectConnection: includeDevicesWithoutObjectConnection
		});
		this.props.unsavedDataCallback(true);
	}

	handleGenerateReportData(
		formatType: ReportRenderFormatEnum,
		separateReports: boolean,
		generateType: ReportGenerateTypeEnum,
		scheduleFrequency: ReportFrequencyEnum,
		scheduleDay: ReportDaysEnum,
		scheduleTime: Date,
		scheduleTimezoneId: string,
		sendAsMail: boolean,
		receivers: EntityData[]
	) {
		this.props.reportStoreActionCreators.setScheduleData({
			formatType: formatType,
			separateReports:
				this.props.reportData.templateDataType === TemplateData.ObjectRawMessage &&
				(this.props.reportData.reportSelectedEntities.length > 1 ||
					(this.props.reportData.reportSelectedEntities.length === 1 &&
						this.props.reportData.reportSelectedEntities[0].entityType === EntityTypeEnum.Group))
					? true
					: separateReports,
			generateType: generateType,
			scheduleFrequency: scheduleFrequency,
			scheduleDay: scheduleDay,
			scheduleTime: scheduleTime,
			scheduleTimezoneId: scheduleTimezoneId,
			sendAsMail: sendAsMail,
			receivers: receivers,
			unsavedData: true,
		});
		this.props.unsavedDataCallback(true);
	}

	canIncludePrivateData(): boolean {
		return (
			//first condition
			(this.props.customerPrivacyLevel !== PrivacySettingsEnum.ShowPrivateData ||
				!(this.props.customerTripTypes & TripTypesEnum.Private)) &&
			(this.props.userIsDriver || this.props.userCanSeePrivateData) ||
			//second condition
			(this.props.customerTrackTypeSpecification && this.props.customerPrivacyLevel !== PrivacySettingsEnum.ShowPrivateData)
		);
	}

	getIncludePrivateData(): JSX.Element {
		if (this.canIncludePrivateData()) {
			return (
				<span>
					<span className="e-text" style={{ fontWeight: 'bold' }}>
						{TranslateText('reports.parameter.includePrivateData') + ': '}
					</span>
					{this.props.reportData.includePrivateData ? (
						<span> {TranslateText('common.yes') + ' '}</span>
					) : (
						<span> {TranslateText('common.no') + ' '}</span>
					)}
				</span>
			);
		}
		return null;
	}

	getIncludeMaxSpeed(): JSX.Element {
		return (
			<span>
				<span className="e-text" style={{ fontWeight: 'bold', marginLeft: '4px' }}>
					{TranslateText('reports.parameter.includeMaxSpeed') + ': '}
				</span>
				{this.props.reportData.includeMaxSpeed ? (
					<span> {TranslateText('common.yes') + ' '}</span>
				) : (
					<span> {TranslateText('common.no') + ' '}</span>
				)}
			</span>
		);
	}

	getIncludeDevicesWithoutObjectConnection(): JSX.Element {
		return (
			<span>
				<span className="e-text" style={{ fontWeight: 'bold', marginLeft: '4px' }}>
					{TranslateText('reports.parameter.includeDevicesWithoutObjectConnection') + ': '}
				</span>
				{this.props.reportData.includeDevicesWithoutObjectConnection ? (
					<span> {TranslateText('common.yes') + ' '}</span>
				) : (
					<span> {TranslateText('common.no') + ' '}</span>
				)}
			</span>
		);
	}

	getCalculateVisitLastTripOfDayInReport(): JSX.Element {
		return (
			<span>
				<span className="e-text" style={{ fontWeight: 'bold', marginLeft: '4px' }}>
					{TranslateText('fields.calculateVisitLastTripOfDay') + ': '}
				</span>
				{this.props.reportData.calculateVisitLastTripOfDayInReport ? (
					<span> {TranslateText('common.yes') + ' '}</span>
				) : (
					<span> {TranslateText('common.no') + ' '}</span>
				)}
			</span>
		);
	}

	getTripTypeLabel(): string {
		switch ((this.props.reportData.tripTypeParameter as TripTypesEnum) & this.getDefaultTripType()) {
			case TripTypesEnum.Business:
				return TranslateText('reports.parameter.business');
			case TripTypesEnum.Private:
				return TranslateText('reports.parameter.private');
			case TripTypesEnum.Commuting:
				return TranslateText('reports.parameter.commuting');
			case TripTypesEnum.Business | TripTypesEnum.Private:
				return TranslateText('reports.parameter.businessAndPrivate');
			case TripTypesEnum.Business | TripTypesEnum.Commuting:
				return TranslateText('reports.parameter.businessAndCommuting');
			case TripTypesEnum.Private | TripTypesEnum.Commuting:
				return TranslateText('reports.parameter.privateAndCommuting');
			case TripTypesEnum.Business | TripTypesEnum.Private | TripTypesEnum.Commuting:
				return TranslateText('reports.parameter.businessPrivateAndCommuting');
			default:
				return '';
		}
	}

	isStepsLookupVisible(currentStep: number) {
		return currentStep > 1;
	}
	setValidationsSteps(data: number) {
		let validations = this.state.validationsSteps;
		validations.push(data);
		this.setState({validationsSteps: validations});
	}

	resetReport() {
		this.props.reportStoreActionCreators.resetReport();
		this.props.reportStoreActionCreators.setReportData({
			reportPeriodType: this.getDefaultReportPeriodType(),
			reportHeaderTitle: TranslateText('reports.newReport'),
			calculateVisitLastTripOfDayInReport: this.getDefaultCalculateVisitLastTripOfDayInReports(),
		});
		this.props.reportStoreActionCreators.setTripData({
			tripDistanceParameter: this.props.currentSession.reportsSettings?.defaultMinimalTripInReport ?? 0.1,
			tripTypeParameter: this.getUserDefaultTripType(),
		});
		this.props.reportStoreActionCreators.setScheduleData({
			formatType: this.props?.reportFormatPreference ?? ReportRenderFormatEnum.pdf,
			scheduleTimezoneId: this.customerTimezoneId,
		});

		this.setState({
			stepsNavigated: [],
			validationsSteps: []
		});

		setTimeout(() => {
			this.getCurrentUserEntityData();
			this.handleIncludePrivateDataChangedCallback(this.canIncludePrivateData());
		}, 100);
	}

	setParameterStepStyle(stepNumber: number) {
		if ((stepNumber === 3 &&
				(this.props.reportData.templateDataType === TemplateData.DeviceExport)) ||
			(stepNumber === 4 &&
				(this.props.reportData.templateDataType === TemplateData.DeviceExport)) ||
			(stepNumber === 5 &&
				(this.props.reportData.templateDataType === TemplateData.ObjectRawMessage ||
				this.props.reportData.templateDataType === TemplateData.ObjectCurrentMileage ||
				this.props.reportData.templateDataType === TemplateData.CurrentObjectPosition ||
				this.props.reportData.templateDataType === TemplateData.LoginReason ||
				this.props.reportData.templateDataType === TemplateData.DriverRegistration ||
				this.props.reportData.templateDataType === TemplateData.ObjectRegistration))
		) {
			return 'disabled-wizard-step';
		}
		return this.setStyleClassBasedOnCurrentStep(stepNumber, this.props.reportData.currentStep);
	}

	async saveReport() {
		this.setState({ disableGenerate: true });
		const customerId = this.props.globalCustomer ? this.props.globalCustomer.id : this.props.customerId;

		if (this.navigationDisabled(6)) {
			this.setState({ disableGenerate: false });
			return;
		}
		const personIds = this.props.reportData.reportSelectedEntities.filter(
			(e) => e.entityType === EntityTypeEnum.Person || e.entityType === EntityTypeEnum.Driver
		);
		const objectIds = this.props.reportData.reportSelectedEntities.filter(
			(e) => e.entityType === EntityTypeEnum.Object || e.entityType === EntityTypeEnum.Asset
		);
		const groupsIds = this.props.reportData.reportSelectedEntities.filter(
			(e) => e.entityType === EntityTypeEnum.Group
		);

		if (!!this.props.reportData.reportCustomPeriodInterval) {
			if (typeof this.props.reportData.reportCustomPeriodInterval[0] === 'object') {
				this.props.reportData.reportCustomPeriodInterval[0] = moment(
					this.props.reportData.reportCustomPeriodInterval[0]
				).format('YYYY-MM-DDTHH:mm:ss');
			}
			if (typeof this.props.reportData.reportCustomPeriodInterval[1] === 'object') {
				this.props.reportData.reportCustomPeriodInterval[1] = moment(
					this.props.reportData.reportCustomPeriodInterval[1]
				).format('YYYY-MM-DDTHH:mm:ss');
			}
		}

		const reportParameters = {
			reportPeriodType: this.props.reportData.reportPeriodType?.value,
			reportCustomPeriodInterval: this.props.reportData.reportCustomPeriodInterval ?? [
				this.defaultStartDateTime,
				this.defaultEndDateTime,
			],
			reportPersonIds: personIds,
			reportObjectIds: objectIds,
			reportGroupIds: groupsIds,
			tripTypeParameter: this.props.reportData.tripTypeParameter,
			tripDistanceParameter: this.props.reportData.tripDistanceParameter,
			formatType: this.props.reportData.formatType,
			separateReports: this.props.reportData.separateReports,
			scheduleFrequency: this.props.reportData.scheduleFrequency,
			scheduleDay: this.props.reportData.scheduleDay,
			scheduleTime: (this.props.reportData.scheduleTime ?? new Date()).toLocaleTimeString(),
			scheduleTimezoneId: this.props.reportData.scheduleTimezoneId,
			sendAsMail: this.props.reportData.sendAsMail,
			mailReceivers: this.props.reportData.receivers,
			dateFormat: DateTimeUtil.localDateFormat(),
			timeFormat: DateTimeUtil.localTimeFormat(),
			timeZone: this.props.reportData.timeZone,
			customerId: this.props.reportData.customerId ?? customerId,
			personId: this.props.reportData.personId ?? this.props.personId,
			includeInactive: this.props.reportData.includeInactive,
			includePrivateData: this.props.reportData.includePrivateData,
			includeMaxSpeed: this.props.reportData.includeMaxSpeed,
			calculateVisitLastTripOfDayInReport: this.props.reportData.calculateVisitLastTripOfDayInReport,
			templates: this.props.reportData.templates,
			locations: this.props.reportData.locations,
			objectTypes: this.props.reportData.objectTypes,
			fuelTypes: this.props.reportData.fuelTypes,
			selectedSummaries: this.props.reportData.selectedSummaries,
			outsideBusinessHours: this.props.reportData.outsideBusinessHours,
			includeDevicesWithoutObjectConnection: this.props.reportData.includeDevicesWithoutObjectConnection
		};

		if(this.props.reportData.templateDataType === TemplateData.DeviceExport)
		{
			reportParameters.reportPeriodType = null;
		}

		if (this.props.reportData.generateType === ReportGenerateTypeEnum.GenerateNow) {
			const timezoneId = await this.getCustomerTimezoneIdWithoutImpersonation();
			this.props.reportData.scheduleTimezoneId = timezoneId ?? this.props.reportData.scheduleTimezoneId;
			reportParameters.scheduleTimezoneId = timezoneId ?? reportParameters.scheduleTimezoneId;
		}

		const report = {
			templateId: this.props.reportData.selectedTemplateId,
			selectedCategoryId: this.props.reportData.reportCategoryId,
			requestedByUserId: this.props.userId,
			reportName: this.props.reportData.reportName,
			reportNotes: this.props.reportData.reportNotes,
			reportParameters: reportParameters,
			oneTimeExecution: this.props.reportData.generateType === ReportGenerateTypeEnum.GenerateNow,
			scheduleFrequency: this.props.reportData.scheduleFrequency,
			scheduleDay: this.props.reportData.scheduleDay,
			scheduleTime: (this.props.reportData.scheduleTime ?? new Date()).toLocaleTimeString(),
			scheduleTimezoneId: this.props.reportData.scheduleTimezoneId,
			sessionId: this.props.currentSession?.currentSessionId ?? '00000000-0000-0000-0000-000000000000',
			id: this.props.reportData.reportId,
			customerId: this.props.reportData.customerId ?? customerId,
			onlyForExport: false
		};

		if (this.props.reportData.isFavorite) {
			ajaxUtil
				.post(`${GlobalSettings.reportingApi}/updateFavoriteReport`, report)
				.then(() => {
					this.setState({ disableGenerate: false });
					this.props.redirectAfterSaveCallback(1);
					setTimeout(() => {
						this.resetReport();
					}, 100);
					this.props.unsavedDataCallback(false);
				})
				.catch((error) => {
					this.setState({ disableGenerate: false });
					console.log(error);
				});
		} else if (this.props.reportData.isPlanned) {
			ajaxUtil
				.post(`${GlobalSettings.reportingApi}/updatePlannedReport`, report)
				.then(() => {
					this.setState({ disableGenerate: false });
					this.props.redirectAfterSaveCallback(2);
					setTimeout(() => {
						this.resetReport();
					}, 100);
					this.props.unsavedDataCallback(false);
				})
				.catch((error) => {
					this.setState({ disableGenerate: false });
					console.log(error);
				});
		} else {
			ajaxUtil
				.post(GlobalSettings.reportingApi, report)
				.then(() => {
					this.setState({ disableGenerate: false });
					if (this.props.reportData.generateType === ReportGenerateTypeEnum.GenerateNow) {
						//go to generated reports tab
						this.props.redirectAfterSaveCallback(4, true);
					} else {
						//go to planned reports tab
						this.props.redirectAfterSaveCallback(2);
					}
					setTimeout(() => {
						this.resetReport();
					}, 100);
					this.props.unsavedDataCallback(false);
				})
				.catch((error) => {
					this.setState({ disableGenerate: false });
					console.log(error);
				});
		}
	}

	clearValidations() {
		this.setState({validationsSteps: []});
	}

	render() {
		return (
			<div>
				<div
					className="e-text e-ellipsistooltip"
					style={{
						fontSize: '20px',
						marginTop: '7px',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}}
				>
					{this.props.reportData.reportHeaderTitle}
				</div>
				<hr />
				<div>
					<ul id="horizontal-list" className="e-text" style={{ cursor: 'pointer' }}>
						<li
							onClick={() => this.goToStep(1)}
							className={this.setStyleClassBasedOnCurrentStep(1, this.props.reportData.currentStep)}
						>
							{'1.' + TranslateText('reports.chooseReport')}
						</li>
						<li
							onClick={() => this.goToStep(2)}
							className={this.setStyleClassBasedOnCurrentStep(2, this.props.reportData.currentStep)}
						>
							{'2.' + TranslateText('reports.basicInformation')}
						</li>
						<li
							onClick={() => this.goToStep(3)}
							className={this.setParameterStepStyle(3)}
						>
							{'3.' + TranslateText('reports.period')}
						</li>
						<li
							onClick={() => this.goToStep(4)}
							className={this.setParameterStepStyle(4)}
						>
							{'4.' +
								(this.props.reportData.templateEntityType === null
									? this.props.driverIdentification
										? TranslateText('reports.objectsDriversPersons')
										: TranslateText('reports.objectsPersons')
									: this.props.reportData.templateEntityType === EntityTypeEnum.Object
									? TranslateText('reports.objects')
									: this.props.reportData.templateEntityType === EntityTypeEnum.PersonReport
									? TranslateText('reports.persons')
									: TranslateText('reports.drivers'))}
						</li>
						<li onClick={() => this.goToStep(5)} className={this.setParameterStepStyle(5)}>
							{'5.' + TranslateText('reports.parameters')}
						</li>
						<li
							onClick={() => this.goToStep(6)}
							className={this.setStyleClassBasedOnCurrentStep(6, this.props.reportData.currentStep)}
						>
							{'6.' + TranslateText('reports.downloadSchedule')}
						</li>
					</ul>
				</div>

				<div id="wizard-content" className="wizard-content">
					<div className="wizard-content-scroll">
						{this.isStepsLookupVisible(this.props.reportData.currentStep) ? (
							<StepsLookup
								ref={this.stepsLookupRef}
								unsavedDataCallback={this.props.unsavedDataCallback}
								previousTemplateDataType={this.state.previousTemplateDataType}
								reportsSettings={this.props.currentSession.reportsSettings}
								driverIdentification={this.props.driverIdentification}
								assetTracking={this.props.assetTracking}
								customerPrivacyLevel={this.props.customerPrivacyLevel}
								userCanSeePrivateData={this.props.userCanSeePrivateData}
								userIsDriver={this.props.userIsDriver}
								includePrivateData={this.props.reportData.includePrivateData}
								tripType={this.props.reportData.tripTypeParameter}
								clearValidations={this.clearValidations.bind(this)}
								customerTripTypes={
									this.props.reportData.tripTypeParameter & this.getDefaultTripType()
								}
								setValidationsSteps={this.setValidationsSteps.bind(this)}
								validationsSteps={this.state.validationsSteps}
								customPeriodInterval={
									this.props.reportData.reportCustomPeriodInterval ?? [
										this.defaultStartDateTime,
										this.defaultEndDateTime,
									]
								}
								periodType={
									this.props.reportData.templateDataType === TemplateData.CurrentObjectPosition ||
									this.props.reportData.templateDataType === TemplateData.ObjectCurrentMileage
										? ReportPeriodsEnum.Today
										: this.props.reportData.reportPeriodType
								}
								reportName={this.props.reportData.reportName}
								currentStep={this.props.reportData.currentStep}
								navigateToCallback={this.goToStep.bind(this)}
								selectedTemplateName={this.props.reportData.reportTemplateName}
								selectedTemplateCategory={this.props.reportData.reportCategory}
								outsideBusinessHours={this.props.reportData.outsideBusinessHours}
								getIncludePrivateData={() => this.getIncludePrivateData()}
								getIncludeMaxSpeed={this.getIncludeMaxSpeed.bind(this)}
								getCalculateVisitLastTripOfDayInReport={this.getCalculateVisitLastTripOfDayInReport.bind(
									this
								)}
								selectedPersonsNames={this.props.reportData.reportSelectedEntities
									.filter(
										(e) =>
											e !== null &&
											(e.entityType === EntityTypeEnum.Person ||
												e.entityType === EntityTypeEnum.Driver)
									)
									.map((e) => e.text)
									.join(', ')}
								selectedObjectsNames={this.props.reportData.reportSelectedEntities
									.filter((e) => e !== null && e.entityType === EntityTypeEnum.Object)
									.map((e) => e.text)
									.join(', ')}
								selectedAssetsNames={this.props.reportData.reportSelectedEntities
									.filter((e) => e !== null && e.entityType === EntityTypeEnum.Asset)
									.map((e) => e.text)
									.join(', ')}
								selectedGroupsNames={this.props.reportData.reportSelectedEntities
									.filter((e) => e !== null && e.entityType === EntityTypeEnum.Group)
									.map((e) => e.text)
									.join(', ')}
								setTripTypeParameter={this.getTripTypeLabel()}
								setTripDistanceParameter={this.props.reportData.tripDistanceParameter.toString()}
								customerTrackTypeSpecification={this.getDefaultCustomerTrackTypeSpecification()}
								templateEntityType={this.props.reportData.templateEntityType}
								templateDataType={this.props.reportData.templateDataType}
								templates={this.state.durationTemplates.concat(this.state.digitalTemplates)}
								locations={this.state.locations}
								objectTypes={this.state.objectTypes}
								fuelTypes={this.state.fuelTypes}
								selectedLocations={this.props.reportData.locations}
								selectedtemplates={this.props.reportData.templates}
								selectedSummaries={this.props.reportData.selectedSummaries}
								selectedFuelTypes={this.props.reportData.fuelTypes}
								selectedObjectTypes={this.props.reportData.objectTypes}
								getIncludeDevicesWithoutObjectConnection={this.getIncludeDevicesWithoutObjectConnection.bind(this)}
								selectedEntities={this.props.reportData.reportSelectedEntities}
							/>
						) : null}
						<div ref={this.wizardPageRef} className="wizard-page">
							{this.isStepVisible(1, this.props.reportData.currentStep) ? (
								<ReportsTemplates
									selectedTemplateCallback={this.handleTemplateSection.bind(this)}
									selectedTemplateId={this.props.reportData.selectedTemplateId}
									selectedCategoryId={this.props.reportData.reportCategoryId}
									reportTemplates={this.props.reportData.data}
								/>
							) : null}
							{this.isStepVisible(2, this.props.reportData.currentStep) ? (
								<BasicReportInformation
									filledBasicInformationCallback={this.handleReportBasicData.bind(this)}
									setReportName={this.props.reportData.reportName}
									setReportNotes={this.props.reportData.reportNotes}
								/>
							) : null}
							{this.isStepVisible(3, this.props.reportData.currentStep) ? (
								<ReportPeriod
									reportsSettings={this.props.currentSession.reportsSettings}
									templateDataType={this.props.reportData.templateDataType}
									defaultPeriodType={this.props.reportData.reportPeriodType}
									defaultCustomPeriodStart={
										this.props.reportData.reportCustomPeriodInterval?.length > 0
											? this.props.reportData.reportCustomPeriodInterval[0]
											: this.defaultStartDateTime
									}
									defaultCustomPeriodEnd={
										this.props.reportData.reportCustomPeriodInterval?.length > 0
											? this.props.reportData.reportCustomPeriodInterval[1]
											: this.defaultEndDateTime
									}
									filledPeriodCallback={this.handlePeriodCallback.bind(this)}
									validationChangedCallback={this.handlePeriodValidationChangedCallback.bind(this)}
								/>
							) : null}
							<div hidden={!this.isStepVisible(4, this.props.reportData.currentStep)}>
								<ReportObjectPerson
									driverIdentification={this.props.driverIdentification}
									isEditMode={this.props.reportData.isFavorite || this.props.reportData.isPlanned}
									selectedReportEntities={this.props.reportData.reportSelectedEntities}
									unsavedDataCallback={this.props.unsavedDataCallback}
									filledObjectPersonCallback={this.handleObjectPersonData.bind(this)}
									templateEntityType={this.props.reportData.templateEntityType}
									templateDataType={this.props.reportData.templateDataType}
									includeInactive={this.props.reportData.includeInactive}
									includeInactiveChangedCallback={this.handleIncludeInactiveChangedCallback.bind(
										this
									)}
									receivedDataList={
										this.props.reportData.templateEntityType === EntityTypeEnum.Person ||
										this.props.reportData.templateEntityType === EntityTypeEnum.PersonReport
											? this.state.driversObjectsReceiversCount.drivers
											: this.state.driversObjectsReceiversCount.objects
									}
									groupsList={this.state.driversObjectsReceiversCount.groups}
								/>
							</div>
							{this.isStepVisible(5, this.props.reportData.currentStep) ? (
								<ReportParameters
									filledParametersCallback={this.handleParametersData.bind(this)}
									unsavedDataCallback={this.props.unsavedDataCallback}
									setTripType={this.props.reportData.tripTypeParameter}
									setTripDistance={this.props.reportData.tripDistanceParameter}
									setIncludePrivateData={this.props.reportData.includePrivateData}
									setIncludeMaxSpeed={this.props.reportData.includeMaxSpeed}
									setCalculateVisitLastTripOfDayInReport={
										this.props.reportData.calculateVisitLastTripOfDayInReport
									}
									customerTrackTypeSpecification={this.getDefaultCustomerTrackTypeSpecification()}
									customerTripTypes={
										this.props.reportData.tripTypeParameter & this.getDefaultTripType()
									}
									customerPrivacyLevel={this.props.customerPrivacyLevel}
									durationTemplates={this.state.durationTemplates}
									locations={this.state.locations}
									digitalTemplates={this.state.digitalTemplates}
									selectedTemplates={this.props.reportData.templates}
									selectedLocations={this.props.reportData.locations}
									selectedSummaries={this.props.reportData.selectedSummaries}
									trackType={this.getDefaultTripType()}
									templateDataType={this.props.reportData.templateDataType}
									userCanSeePrivateData={this.props.userCanSeePrivateData}
									objectTypes={this.state.objectTypes}
									fuelTypes={this.state.fuelTypes}
									selectedObjectTypes={this.props.reportData.objectTypes}
									selectedFuelTypes={this.props.reportData.fuelTypes}
									userIsDriver={this.props.userIsDriver}
									setIncludeDevicesWithoutObjectConnection={this.props.reportData.includeDevicesWithoutObjectConnection}
								/>
							) : null}
							{this.isStepVisible(6, this.props.reportData.currentStep) ? (
								<ReportGenerate
									setScheduleFrequency={this.props.reportData.scheduleFrequency}
									unsavedDataCallback={this.props.unsavedDataCallback}
									setScheduleDay={this.props.reportData.scheduleDay}
									setScheduleTime={this.props.reportData.scheduleTime}
									setScheduleTimezoneId={this.props.reportData.scheduleTimezoneId}
									customerTimeZoneId={this.customerTimezoneId}
									setGenerateType={this.props.reportData.generateType}
									setFormatType={this.props.reportData.formatType}
									setSeparateReports={this.props.reportData.separateReports}
									setSendAsMail={this.props.reportData.sendAsMail}
									setReceivers={this.props.reportData.receivers}
									showPeriodInThePastMessage={this.props.reportData.periodInThePast}
									filledGenerateCallback={this.handleGenerateReportData.bind(this)}
									isEditOfFavoriteReport={this.props.reportData.isFavorite}
									templateEntityType={this.props.reportData.templateEntityType}
									isEditOfPlannedReport={this.props.reportData.isPlanned}
									selectedEntities={this.props.reportData.reportSelectedEntities}
									reportCategory={this.props.reportData.reportCategory}
									templateDataType={this.props.reportData.templateDataType}
									receiversList={this.state.driversObjectsReceiversCount.receivers}
									groupsList={this.state.driversObjectsReceiversCount.groups}
								/>
							) : null}
						</div>
					</div>
					<div className="wizard-buttons-container">
						{this.props.reportData.currentStep === 6 ? (
							<Button
								className={'primary-button'}
								style={{ textTransform: 'none' }}
								disabled={
									this.state.disableGenerate ||
									this.navigationDisabled(this.props.reportData.currentStep) ||
									(this.state.validationsSteps.length > 0 && this.state.validationsSteps.some((x) => x <= this.props.reportData.currentStep))
								}
								onClick={this.saveReport.bind(this)}
							>
								{!this.props.reportData.isFavorite && !this.props.reportData.isPlanned
									? TranslateText('reports.generate')
									: TranslateText('reports.save')}
							</Button>
						) : (
							<Button
								className={'primary-button'}
								style={{ textTransform: 'none' }}
								disabled={this.navigationDisabled(this.props.reportData.currentStep) ||
									(this.state.validationsSteps.length > 0 && this.state.validationsSteps.some((x) => x <= this.props.reportData.currentStep))}
								onClick={this.goToNextStep.bind(this)}
							>
								{TranslateText('reports.saveAndNext')}
							</Button>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default connector(NewReport);
